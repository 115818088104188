import { FALHA_LOGIN, LIMPAR_ERROS_LOGIN } from '../actions/login_actions';

export const INITIAL_STATE = {

  loginFailMessage: '',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FALHA_LOGIN:
      return { ...state, loginFailMessage: action.mensagem };
    case LIMPAR_ERROS_LOGIN:
      return { ...state, loginFailMessage: '' };
    default:
      return state;
  }
}
