import {
  BulbOutlined, CalendarOutlined, HeartFilled, LikeOutlined, UserOutlined,
} from '@ant-design/icons';
import { Rate } from 'antd';
import { maskJs } from 'mask-js';
import moment from 'moment';
import React from 'react';

export default function detalheConversa(conversa) {
  return [
    <div style={{ fontSize: 25 }} className="text-center">{maskJs('+99 (99) 9999?9-9999', conversa.telefoneCliente)}</div>,
    <div>
      <CalendarOutlined />
      {' '}
      Data da Conversa:
      {' '}
      {moment(conversa.dataConversa).locale('pt-br').format('L')}
      {' '}
    </div>,
    <div>
      <HeartFilled />
      {' '}
      Nota do Cliente:
      {' '}
      {conversa.feedback ? conversa.feedback : 'Cliente não Informou'}
      {' '}
      <br />
      {' '}
      <Rate id="rate" value={conversa.feedback ? Math.ceil(conversa.feedback / 2) : 0} disabled />
    </div>,
    <div>
      <UserOutlined />
      {' '}
      Nome de Contato:
      {' '}
      {conversa.nomeContato}
      {' '}
    </div>,
    <div>
      <BulbOutlined />
      {' '}
      Sugestão Cliente:
      {' '}
      {conversa.sugestao ? conversa.sugestao : 'Cliente não Informou'}
      {' '}
    </div>,
    <div>
      <LikeOutlined />
      {' '}
      Teve Interesse Pelo Serviço:
      {' '}
      {conversa.demonstrouInteresse ? 'Sim' : 'Sim'}
      {' '}
    </div>,
  ];
}
