import {
  ADICIONA_MENSAGEM, BUSCAR_DADOS_DASHBOARD, BUSCAR_DADOS_GRAFICO, BUSCAR_LISTA_CONVERSAS, BUSCAR_UMA_CONVERSA, COMECA_LIVECHAT, ENCERRAR_LIVECHAT, LIMPAR_LISTA_CONVERSAS, LIVECHAT_INICIADO, SALVA_INTERVAL_ID, ATUALIZA_STATUS_MENSAGEM_LIDA,
} from '../actions/cockpit_actions';

export const INITIAL_STATE = {

  dashboard_data: {

    totalConversas: '',
    totalMensagens: '',
    mediaFeedback: '',
    totalFinalizada: '',
    totalNaoFinalizada: '',
    cnpjInformados: '',
    cpfInformados: '',
    cnpjNaoInformados: '',
    cnpjInvalidos: '',
  },

  grafico_data: null,

  conversa_data: {},

  pagination_config: {},

  conversa_selecionada: {},

  livechat: { iniciou: false },

  interval_id: 0,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case BUSCAR_DADOS_DASHBOARD:
      return { ...state, dashboard_data: action.dados };
    case BUSCAR_DADOS_GRAFICO:
      return { ...state, grafico_data: action.dados };
    case BUSCAR_LISTA_CONVERSAS:

      const { dados } = action;

      const paginationConfig = {

        total: dados.totalElements,
        pageSize: dados.size,
        current: dados.number + 1,
        hideOnSinglePage: true,
      };

      return { ...state, conversa_data: action.dados, pagination_config: paginationConfig };
    case BUSCAR_UMA_CONVERSA:
      return { ...state, conversa_selecionada: action.dados };

    case LIVECHAT_INICIADO:
      return { ...state, livechat: action.livechat };

    case SALVA_INTERVAL_ID:
      return { ...state, interval_id: action.interval_id };

    case LIMPAR_LISTA_CONVERSAS:
      return { ...state, conversa_data: {}, conversa_selecionada: {} };

    case COMECA_LIVECHAT:
      return { ...state, livechat: { iniciou: true, conversaId: action.conversaId } };

    case ENCERRAR_LIVECHAT:
      return { ...state, livechat: {} };
    case ADICIONA_MENSAGEM:
      if (state.conversa_selecionada) (state.conversa_selecionada.mensagens || []).push(action.mensagem);
      return { ...state, conversa_selecionada: state.conversa_selecionada };
    case ATUALIZA_STATUS_MENSAGEM_LIDA:
      const newState = { ...state };
      newState.conversa_data.content[action.key].novaMensagemRecebida = false;
      return { ...newState, conversa_data: state.conversa_data };

    default:
      return state;
  }
}
