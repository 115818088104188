import {
  buscarDadosDashboardService,
  buscarDadosGraficoService,
  buscarListaDeConversasService,
  buscarUmaConversaService,
  exportaConversasService,
  marcarConversaComoLidaService,
} from '../servicos/cockpit_service';
import {
  comecaLiveChatService, encerraLiveChatService, enviaMensagemLiveChatService,
  liveChatEstaIniciadoService,
} from '../servicos/livechat_service';
import { exportaConversas } from '../utils/utils';

export const BUSCAR_DADOS_DASHBOARD = 'BUSCAR_DADOS_DASHBOARD';
export const BUSCAR_DADOS_GRAFICO = 'BUSCAR_DADOS_GRAFICO';
export const BUSCAR_LISTA_CONVERSAS = 'BUSCAR_LISTA_CONVERSAS';
export const BUSCAR_UMA_CONVERSA = 'BUSCAR_UMA_CONVERSA';
export const LIMPAR_LISTA_CONVERSAS = 'LIMPAR_LISTA_CONVERSAS';
export const LIVECHAT_INICIADO = 'LIVECHAT_INICIADO';
export const ENVIAR_MENSAGEM_LIVECHAT = 'ENVIAR_MENSAGEM_LIVECHAT';
export const ENCERRAR_LIVECHAT = 'ENCERRAR_LIVECHAT';
export const COMECA_LIVECHAT = 'COMECA_LIVECHAT';
export const SALVA_INTERVAL_ID = 'SALVA_INTERVAL_ID';
export const ADICIONA_MENSAGEM = 'ADICIONA_MENSAGEM';
export const ATUALIZA_STATUS_MENSAGEM_LIDA = 'ATUALIZAR_STATUS_MENSAGEM_LIDA';

export const buscarDadosDashboard = () => (dispatch) => buscarDadosDashboardService().then((resp) => {
  dispatch({

    type: BUSCAR_DADOS_DASHBOARD,
    dados: resp,

  });

  return resp;
});

export const buscarDadosGrafico = (data) => (dispatch) => buscarDadosGraficoService(data).then((resp) => {
  dispatch({

    type: BUSCAR_DADOS_GRAFICO,
    dados: resp,

  });

  return resp;
});

export const buscarListaDeConversas = ({ search }) => (dispatch) => buscarListaDeConversasService({ search }).then((resp) => {
  dispatch({

    type: BUSCAR_LISTA_CONVERSAS,
    dados: resp,
  });

  return resp;
});

export const buscarUmaConversa = (conversaId) => (dispatch) => buscarUmaConversaService(conversaId).then((resp) => {
  dispatch({

    type: BUSCAR_UMA_CONVERSA,
    dados: resp,
  });

  return resp;
});

export const limparListaConversas = () => ({

  type: LIMPAR_LISTA_CONVERSAS,
});

export const livechatIniciado = (numero) => (dispatch) => liveChatEstaIniciadoService(numero).then((resp) => {
  dispatch({
    type: LIVECHAT_INICIADO,
    livechat: resp,
  });

  return resp;
});

export const enviarMensagemLiveChat = (obj, arquivo) => () => enviaMensagemLiveChatService(obj, arquivo).then((resp) => resp);

export const adicionaMensagem = (msg) => (dispatch) => {
  dispatch({

    type: ADICIONA_MENSAGEM,
    mensagem: msg,

  });
};

export const marcarConversaComoLida = (conversaId) => () => marcarConversaComoLidaService(conversaId).then((resp) => resp);

export const atualizarStatusMensagemLida = (key) => ({

  type: ATUALIZA_STATUS_MENSAGEM_LIDA,
  key,
});

export const encerrarLiveChat = (numero) => (dispatch) => encerraLiveChatService(numero).then((resp) => {
  dispatch({

    type: ENCERRAR_LIVECHAT,
  });

  return resp;
});

export const comecaLiveChat = (obj) => (dispatch) => comecaLiveChatService(obj).then((resp) => {
  dispatch({
    type: COMECA_LIVECHAT,
    conversaId: obj.conversaId,
  });
  return resp;
});

export const salvaIntervalId = (intervalId) => ({
  type: SALVA_INTERVAL_ID,
  interval_id: intervalId,
});

export const limparLiveChat = () => ({

  type: ENCERRAR_LIVECHAT,
});

export const exportaConversasAction = ({ search }) => () => exportaConversasService({ search }).then((conversas) => {
  exportaConversas(conversas);
  return conversas;
});
