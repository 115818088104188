import Constants from '../utils/constants';

export const liveChatEstaIniciadoService = (numero) => Constants.axiosInstance.get('/livechat/iniciou', { params: { numero } }).then((resp) => resp.data);

export const enviaMensagemLiveChatService = async (obj, arquivo) => {
  const formData = new FormData();
  formData.append('file', arquivo);
  formData.append('request', new Blob([JSON.stringify(obj)], { type: 'application/json' }));

  const resp = await Constants.axiosInstance.post('/livechat', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return resp.data;
};

export const encerraLiveChatService = (numero) => Constants.axiosInstance.get('/livechat/finaliza', { params: { numero } }).then((resp) => resp.data);

export const comecaLiveChatService = ({ numero, conversaId }) => Constants.axiosInstance.get('/livechat/inicia',
  {
    params: {
      numero,
      conversaId,
    },
  });
