import * as am4charts from '@amcharts/amcharts4/charts';
/* Imports */
import * as am4core from '@amcharts/amcharts4/core';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { buscarEtapas, totalPorEtapa } from '../../servicos/cockpit_service';
import Spinner from '../spinner/spinner';

/* Chart code */
// Themes begin
am4core.useTheme(am4themesAnimated);
// Themes end

export default function TotalEtapas() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [key, setKey] = useState('simplificado');

  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    async function preencheGrafico() {
      try {
        setCarregando(true);
        const [etapas, totalEtapa] = await Promise.all([buscarEtapas(), totalPorEtapa()]);
        const newData = etapas.map((etapa) => {
          const etapaAtualTotal = totalEtapa.find((totalEtapa) => etapa.description === totalEtapa.etapaDescription);
          if (etapaAtualTotal) {
            setTotal((oldTotal) => oldTotal + etapaAtualTotal.total);
            return { ...etapa, ...etapaAtualTotal };
          }

          return { ...etapa, total: 0 };
        });
        setCarregando(false);
        setData(newData);
      } catch (error) {
        console.error('Error', error);
        setCarregando(false);
      }
    }

    preencheGrafico();
  }, []);

  function funilSimplificado(data) {
    const newfunil = [];
    let total = 0;
    const negociacao = {
      id: 2,
      description: 'NEGOCIACAO',
      frontendDescription: 'Negociação',
      total: 100,
      next: {},
      etapaDescription: 'NEGOCIACAO',
    };

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].description !== 'PROSPECCAO' && data[i].description !== 'ATENDIMENTO_FINALIZADO') {
        total += data[i].total;
      }
    }
    negociacao.total = total;
    newfunil.push(data[0]);
    newfunil.push(negociacao);
    newfunil.push(data[10]);
    return newfunil;
  }

  useEffect(() => {
    const chart = am4core.create('totaletapachart', am4charts.SlicedChart);
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect
    if (key === 'simplificado') {
      chart.data = funilSimplificado(data);
    } else {
      chart.data = data;
    }

    const series = chart.series.push(new am4charts.FunnelSeries());
    series.colors.step = 2;
    series.dataFields.value = 'total';
    series.dataFields.category = 'frontendDescription';
    series.alignLabels = true;

    series.labelsContainer.paddingLeft = 15;
    series.labelsContainer.width = 200;
    series.legendSettings.itemValueText = '{total}';
    series.slices.template.events.on('hit', (ev) => {
      history.push(`/conversas?etapa=${ev.target.dataItem.dataContext.description}`);
    }, this);
    series.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'left';
  }, [data, key]);

  if (carregando) {
    return (
      <Card
        bordered
        style={{ margin: 5 }}
        bodyStyle={{ padding: 10 }}
        title="Funil de vendas"
      >
        <Spinner />
      </Card>
    );
  }

  return (
    <Card
      bordered
      style={{ margin: 5 }}
      bodyStyle={{ padding: 10 }}
      tabList={
        [
          {
            key: 'simplificado',
            tab: 'Simplificado',
          },
          {
            key: 'completo',
            tab: 'Completo',
          },
        ]
      }
      onTabChange={
        (newKey) => {
          setKey(newKey);
        }
      }
      activeTabKey={key}
      title={(
        <>
          Funil de vendas
          {' '}
          <b>{total ? `(Total: ${total})` : ''}</b>
        </>
      )}
    >
      <div id="totaletapachart" style={{ height: '550px' }} />
    </Card>
  );
}
