import { acaoAlertaSelecionada } from './alerta_actions';

export const SELECIONA_OPCAO_MENU = 'SELECIONA_OPCAO_MENU';

export const selecionaOpcaoMenu = (key) => (dispatch) => {
  if (key === '4') {
    dispatch(acaoAlertaSelecionada('logout'));
  } else {
    dispatch({

      type: SELECIONA_OPCAO_MENU,
      opcao: [key],
    });
  }
};
