import {
  HomeOutlined, LogoutOutlined, MessageOutlined, UserOutlined, FolderOutlined,
} from '@ant-design/icons';
import { Avatar, Layout, Menu } from 'antd';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { selecionaOpcaoMenu } from '../../actions/sider_menu_actions';

const { Sider } = Layout;
const { Item } = Menu;

class SiderCockpit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
    };

    this.onCollapse = this.onCollapse.bind(this);
  }

  onCollapse(collapsed) {
    this.setState({ collapsed });
  }

  render() {
    const { selecionaOpcaoMenu } = this.props;
    const paths = window.location.pathname.split('/');
    const selecionado = paths[paths.length - 1];
    return (
      <div>
        <Sider style={{ background: 'none', height: '100%', borderRight: '0.1px solid lightgray' }} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div className="logged-user text-center">
            <Avatar size={64} icon={<UserOutlined />} />
            <p hidden={this.state.collapsed} className="logged-user-texto">Usuário</p>
          </div>
          <Menu onClick={(e) => selecionaOpcaoMenu(e.key)} theme="light" mode="inline" selectedKeys={[selecionado]}>
            <Item key="cockpit">
              <Link to="/cockpit" />
              <HomeOutlined />
              <span>Home</span>
            </Item>
            <Item key="conversas">
              <Link to="/conversas" />
              <MessageOutlined />
              <span>Conversas</span>
            </Item>
            <Item key="folders">
              <Link to="/folders" />
              <FolderOutlined />
              <span>Folders</span>
            </Item>
            <Menu.Divider />
            <Item key="4">
              <LogoutOutlined />
              <span>Sair</span>
            </Item>
          </Menu>
        </Sider>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {

    opcaoSelecionada: state.sider_menu.opcaoSelecionada,

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ selecionaOpcaoMenu }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SiderCockpit);
