import { LoadingOutlined, WarningFilled } from '@ant-design/icons';
import { DatePicker, Layout } from 'antd';

import moment from 'moment';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';

import { buscarDadosGrafico } from '../../actions/cockpit_actions';

const { Content } = Layout;

class Graficos extends Component {
  constructor() {
    super();

    this.state = {

      grafico_is_fetching: true,

    };
    this.selecionaData = this.selecionaData.bind(this);
  }

  componentDidMount() {
    const dataAtual = moment().add(-14, 'days').format('DD-MM-YYYY');

    this.props.buscarDadosGrafico(dataAtual).then(() => {
      this.setState({ grafico_is_fetching: false });
    }).catch(() => {
      this.setState({ grafico_is_fetching: false, erro_encontrado: true });
    });
  }

  selecionaData(value, defaultString) {
    this.setState({ grafico_is_fetching: true });
    this.props.buscarDadosGrafico(defaultString).then(() => {
      this.setState({ grafico_is_fetching: false });
    }).catch(() => {
      this.setState({ grafico_is_fetching: false, erro_encontrado: true });
    });
  }

  preparaGraficoParaExibicao() {
    if (this.state.grafico_is_fetching) {
      return <div className="text-center" style={{ paddingTop: 30 }}><LoadingOutlined style={{ fontSize: 100 }} /></div>;
    }

    if (this.state.erro_encontrado) {
      return (
        <p className="text-center" style={{ fontSize: 20, color: 'gray' }}>
          <WarningFilled style={{ fontSize: 40 }} />
          {' '}
          <br />
          Houve um problema ao exibir o gráfico. Tente novamente.
        </p>
      );
    }

    return (

      <ResponsiveContainer width="100%" height={280}>
        <LineChart
          data={this.props.graficoData}
          margin={{
            top: 5, right: 50, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" name="Leads" dataKey="conversas" stroke="#b912e3" activeDot={{ r: 8 }} />
          {/* <Line type="monotone" name="Mensagens" dataKey="mensagens" stroke="#4c35a6" /> */}
          <Line type="monotone" name="Em negociação" dataKey="atend_finalizados" stroke="#26a628" />
          <Line type="monotone" name="Em prospecção" dataKey="atend_nfinalizados" stroke="#fab01b" />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  render() {
    return (

      <Layout>
        <Content>
          <div style={{ paddingLeft: 60, paddingBottom: 20 }}>
            <p>Selecione uma Data, para exibir os próximos 15 dias.</p>
            <DatePicker
              onChange={this.selecionaData}
              format="DD-MM-YYYY"
              disabledDate={(current) => current && current > moment().add(-14, 'days')}
              defaultValue={moment()}
              allowClear={false}
            />
          </div>
          {this.preparaGraficoParaExibicao()}
          <br />
        </Content>
      </Layout>

    );
  }
}

function mapStateToProps(state) {
  let graficoData = {};
  if (state.cockpit.grafico_data) {
    state.cockpit.grafico_data.total.forEach((dados) => {
      graficoData[dados.data] = graficoData[dados.data] || {};
      graficoData[dados.data].name = dados.data;
      graficoData[dados.data].conversas = dados.total;
    });
    state.cockpit.grafico_data.finalizadas.forEach((dados) => {
      graficoData[dados.data] = graficoData[dados.data] || {};
      graficoData[dados.data].name = dados.data;
      graficoData[dados.data].atend_finalizados = dados.total;
    });
    state.cockpit.grafico_data.naoFinalizadas.forEach((dados) => {
      graficoData[dados.data] = graficoData[dados.data] || {};
      graficoData[dados.data].name = dados.data;
      graficoData[dados.data].atend_nfinalizados = dados.total;
    });
    state.cockpit.grafico_data.totalMensagens.forEach((dados) => {
      graficoData[dados.data] = graficoData[dados.data] || {};
      graficoData[dados.data].name = dados.data;
      graficoData[dados.data].mensagens = dados.total;
    });
  }

  graficoData = Object.values(graficoData).sort((item1, item2) => moment(item1.name, 'DD-MM-YYYY').diff(moment(item2.name, 'DD-MM-YYYY')));

  return {

    graficoData,

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ buscarDadosGrafico }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Graficos);
