import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, Layout } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { efetuaLogin, falhaAoLogar, limparErrosLogin } from '../../actions/login_actions';
import { basePath as basename } from '../../utils/config.json';
import embratelLeadsLogo from '../../utils/images_paths';
import LeadsFooter from '../footer/leads-footer';

const { Content } = Layout;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuario: '',
      senha: '',
      isLogging: false,
    };
  }

  handleCredenciais(valor, key) {
    const { limparErrosLogin } = this.props;
    limparErrosLogin();

    switch (key) {
      case 'user':
        this.setState({ usuario: valor });
        break;
      case 'senha':
        this.setState({ senha: valor });
        break;
      default:
    }
  }

  handleLoginPorTecla(e) {
    if (e.key === 'Enter') {
      if (!this.verificaDisponibilidadeDoBotaoLogin()) {
        return this.fazerLogin();
      }
    }
    return null;
  }

  fazerLogin() {
    this.setState({ isLogging: true });

    this.props.efetuaLogin(this.state.usuario, this.state.senha).then((resp) => {
      this.setState({ isLogging: false });

      localStorage.setItem('authToken', resp);

      if (resp !== undefined) {
        window.location.pathname = `${basename}/cockpit`;
      }
    }).catch((e) => {
      if (e.response) {
        this.props.falhaAoLogar(e.response.status);
      }

      this.setState({ isLogging: false, erro_encontrado: true });
    });
  }

  falhaDeLogin() {
    if (this.state.erro_encontrado) {
      switch (this.props.mensagemFalha) {
        case 403:
          return (<p className="texto-falha-login">Usuário ou Senha Inválidos.</p>);
        case 500:
          return (<p className="texto-falha-login">Ocorreu um erro. Tente novamente mais tarde.</p>);
        default:
      }
    }
    return null;
  }

  verificaDisponibilidadeDoBotaoLogin() {
    return this.state.usuario.trim() === '' || this.state.senha.trim() === '';
  }

  render() {
    return (
      <Layout>
        <Content style={{ paddingTop: 150 }}>
          <div className="text-center login-grid">
            <img src={embratelLeadsLogo} className="embratel-leads-logo" alt="" />
            <div style={{ padding: 15 }}>
              {this.falhaDeLogin()}
              <Input onKeyUp={(e) => this.handleLoginPorTecla(e)} type="text" onChange={(e) => this.handleCredenciais(e.target.value, 'user')} style={{ paddingBottom: 5 }} addonBefore={<UserOutlined />} placeholder="Usuário" />
              <Input onKeyUp={(e) => this.handleLoginPorTecla(e)} type="password" onChange={(e) => this.handleCredenciais(e.target.value, 'senha')} style={{ paddingTop: 5, paddingBottom: 20 }} addonBefore={<LockOutlined />} placeholder="Senha" />
              <Button disabled={this.verificaDisponibilidadeDoBotaoLogin()} loading={this.state.isLogging} onClick={() => this.fazerLogin()}>Login</Button>
            </div>
          </div>
        </Content>
        <LeadsFooter />
      </Layout>
    );
  }
}

Login.propTypes = {
  limparErrosLogin: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {

    mensagemFalha: state.login.loginFailMessage,

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ efetuaLogin, falhaAoLogar, limparErrosLogin }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
