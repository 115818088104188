import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faPaperclip, faFile } from '@fortawesome/free-solid-svg-icons';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/pt_BR';
import RotasAplicacao from './rotas/routes';

import store from './store';

import 'antd/dist/antd.css';
import './recursos/css/login.css';
import './recursos/css/general_custom_styles.css';
import './recursos/css/custom_fonts.css';
import './recursos/css/header.css';
import './recursos/css/footer.css';
import './recursos/css/cockpit.css';
import './recursos/css/alertas.css';

library.add(faPaperPlane, faPaperclip, faFile);

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <RotasAplicacao />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);
