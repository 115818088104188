import { DeleteOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { removerFolder } from '../../servicos/folder_service';

export default function RemoverFolderButton({ folder, reloadTable }) {
  const [removendo, setRemovendo] = useState(false);
  const [visible, setVisible] = useState(false);

  const remover = async (id) => {
    setRemovendo(true);
    try {
      await removerFolder(id);
    } catch (error) {
      notification.error({ message: 'Erro ao remover o arquivo', description: 'Folder não foi removido, tente novamente' });
      console.error(error);
    }
    setRemovendo(false);
    setVisible(false);
    reloadTable();
  };

  return (
    <Popconfirm
      title={`Remover folder: ${folder.key}?`}
      cancelText="Cancelar"
      okButtonProps={{ loading: removendo }}
      onConfirm={() => remover(folder.id)}
      onCancel={() => setVisible(false)}
      visible={visible}
    >
      <Button onClick={() => setVisible(true)} icon={<DeleteOutlined />} danger>Remover</Button>
    </Popconfirm>
  );
}
