import {
  EditOutlined,
  FileImageOutlined, FileOutlined, FilePdfOutlined, InfoCircleOutlined,
  KeyOutlined, LinkOutlined, PlusSquareOutlined, SettingOutlined,
} from '@ant-design/icons';
import {
  Button, Card, Layout, Space, Table, Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { buscarFolders } from '../../servicos/folder_service';
import SiderCockpit from '../cockpit/sider-cockpit';
import BaixarFolderButton from './BaixarFolderButton';
import ModalFolder from './ModalFolder';
import RemoverFolderButton from './RemoverFolderButton';

function Folder() {
  const { Content } = Layout;

  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({});

  const loadTable = async () => {
    setLoading(true);
    const folderData = await buscarFolders({ current, pageSize });
    setData(folderData.content);
    setCurrent(folderData.number + 1);
    setPageSize(folderData.size);
    setTotal(folderData.totalElements);
    setLoading(false);
  };

  const editFolder = (folder) => {
    setSelectedFolder(folder);
    setModalVisible(true);
  };

  const [columns] = useState([
    {
      title: (
        <div style={{ color: '#909ba7' }}>
          <KeyOutlined />
          {' '}
          Chave
        </div>
      ),
      dataIndex: 'key',
      align: 'center',
      render: (key) => (
        key
      ),
    },
    {
      title: (
        <div style={{ color: '#909ba7' }}>
          <FileOutlined />
          {' '}
          Tipo de Mídia
        </div>
      ),
      dataIndex: 'type',
      align: 'center',
      render: (type) => {
        const color = type === 'image' ? 'magenta' : 'purple';
        const typeText = type === 'image' ? 'Imagem' : 'Documento';
        const icon = type === 'image' ? <FileImageOutlined /> : <FilePdfOutlined />;
        return (
          <Tag color={color} icon={icon}>{typeText}</Tag>
        );
      },
    },
    {
      title: (
        <div style={{ color: '#909ba7' }}>
          <LinkOutlined />
          {' '}
          Download do Arquivo
        </div>
      ),
      align: 'center',
      render: (folder) => (
        <BaixarFolderButton folder={folder} />
      ),
    },
    {
      title: (
        <div style={{ color: '#909ba7' }}>
          <SettingOutlined />
          {' '}
          Ações
        </div>
      ),
      align: 'center',
      render: (folder) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => { editFolder(folder); }}>Editar</Button>
          <RemoverFolderButton folder={folder} reloadTable={loadTable} />
        </Space>
      ),
    },
  ]);

  const onModalVisibleChange = (visibleValue) => {
    if (!visibleValue) {
      setSelectedFolder({});
    }
    setModalVisible(visibleValue);
  };

  useEffect(() => {
    loadTable();
  }, [current, pageSize]);

  const handlePagination = (pagination) => {
    setCurrent(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <Layout>
      <ModalFolder folder={selectedFolder} visible={modalVisible} setVisible={onModalVisibleChange} loadTable={loadTable} />
      <SiderCockpit />
      <Content style={{ background: '#E5E5E5', minHeight: '100vh' }}>
        <Card
          bordered
          title="Folders"
          style={{ margin: 5 }}
          bodyStyle={{ padding: 10 }}
          extra={(
            <Button
              icon={<PlusSquareOutlined />}
              title="Adicionar Folder"
              size="large"
              onClick={() => setModalVisible(true)}
            >
              Adicionar Folder
            </Button>
          )}
        >
          <Table
            onChange={handlePagination}
            loading={loading}
            bordered
            columns={columns}
            dataSource={data}
            pagination={{
              current, pageSize, total, hideOnSinglePage: true,
            }}
            size="small"
            locale={{
              filterReset: 'Resetar',
              triggerDesc: 'Clique para ordenar de forma decrescente',
              triggerAsc: 'Clique para ordenar de forma crescente',
              cancelSort: 'Clique para cancelar ordenação',
              emptyText: (
                <div>
                  <InfoCircleOutlined style={{ fontSize: 30, padding: 10 }} />
                  <p style={{ fontSize: 20 }}>Sem folders</p>
                </div>
              ),
            }}
          />
        </Card>
      </Content>
    </Layout>
  );
}

export default Folder;
