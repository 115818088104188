import Login from '../componentes/login/login';
import LeadsCockpit from '../componentes/cockpit/cockpit';
import Conversas from '../componentes/conversas/conversas';
import PaginaNaoEncontrada from '../componentes/not_found/PaginaNaoEncontrada';
import Folder from '../componentes/folders/Folder';

const routesConfig = {

  login: {
    path: '/login',
    component: Login,

  },

  cockpit: {
    path: '/cockpit',
    component: LeadsCockpit,
  },

  conversas: {
    path: '/conversas',
    component: Conversas,
  },

  notfoundpage: {
    path: '/404',
    component: PaginaNaoEncontrada,
  },

  folders: {
    path: '/folders',
    component: Folder,
  },

};

export default routesConfig;
