import { InboxOutlined } from '@ant-design/icons';
import {
  Modal, notification, Select, Upload,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function ModalMedia({
  visivel, setVisivel, numero, text, conversaId, type, enviarMediaAoCliente, etapas,
}) {
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [enviandoMedia, setEnviandoMedia] = useState(false);
  const [etapaDescription, setEtapaDescription] = useState(undefined);

  const enviaDocumento = async () => {
    const arquivo = listaDocumentos[0];
    setEnviandoMedia(true);
    const obj = {
      numero,
      text,
      conversaId,
      type,
      etapaDescription,
    };

    try {
      await enviarMediaAoCliente(obj, arquivo);
      setListaDocumentos([]);
      setEtapaDescription(undefined);

      setVisivel(false);
    } catch (e) {
      console.error(e);
    }
    setEnviandoMedia(false);
  };

  const cancelaEnvioDocumento = () => {
    setListaDocumentos([]);
    setEtapaDescription(undefined);
    setVisivel(false);
  };

  return (
    <Modal
      title="Escolha o documento que deseja enviar"
      visible={visivel}
      onOk={enviaDocumento}
      confirmLoading={enviandoMedia}
      onCancel={cancelaEnvioDocumento}
      okText="Enviar arquivo"
      cancelText="Cancelar"
      okButtonProps={
        { disabled: (!listaDocumentos.length || listaDocumentos[0].status === 'error') }
      }
    >
      <Upload.Dragger
        fileList={listaDocumentos}
        listType="picture"
        onRemove={
          () => {
            setListaDocumentos([]);
          }
        }
        beforeUpload={(file) => {
          if (file.size > 104857600) {
            file.status = 'error';

            notification.error({
              message: 'Arquivo muito grande!',
              description: 'O tamanho de máximo de um documento é 100MB',
            });
          }
          setListaDocumentos([file]);
          return false;
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Clique ou arraste um arquivo para a esta área</p>

      </Upload.Dragger>
      <Select
        value={etapaDescription}
        showSearch
        style={{ width: '100%', marginTop: 5 }}
        placeholder="Selecione a fase do funil (Opcional)"
        allowClear
        onChange={(etapa) => { setEtapaDescription(etapa); }}
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {
          etapas.map((etapa) => <Select.Option key={etapa.id} value={etapa.description}>{etapa.frontendDescription}</Select.Option>)
        }

      </Select>

    </Modal>
  );
}

ModalMedia.propTypes = {
  visivel: PropTypes.bool.isRequired,
  setVisivel: PropTypes.func.isRequired,
  numero: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  conversaId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['document', 'image']).isRequired,
  enviarMediaAoCliente: PropTypes.func.isRequired,
  etapas: PropTypes.array.isRequired,
};

export default ModalMedia;
