import { Spin, Steps } from 'antd';
import React, { useState } from 'react';

const { Step } = Steps;
function Etapas({
  etapas, etapaAtual, onChange, loading,
}) {
  const indexEtapaAtual = (etapas || []).findIndex((etapa) => etapa.id === (etapaAtual || {}).id);
  const [current, setCurrent] = useState(indexEtapaAtual !== -1 ? indexEtapaAtual : 0);

  function onStepChange(value) {
    setCurrent(value);
    onChange(etapas[value].description);
  }

  return (
    <Spin spinning={loading} size="large">
      <Steps current={current} onChange={onStepChange} direction="vertical">
        {etapas && etapas.map((etapa, index) => {
          const title = etapa.parentFrontendDescription || etapa.frontendDescription;
          const description = etapa.parentFrontendDescription ? etapa.frontendDescription : undefined;
          const processOrWaitStatus = index === indexEtapaAtual ? 'process' : 'wait';
          const status = index < indexEtapaAtual ? 'finish' : processOrWaitStatus;
          return <Step status={status} key={etapa.id} title={title} description={description} />;
        })}
      </Steps>
    </Spin>
  );
}

Etapas.defaultProps = {
  etapaAtual: {},
};

export default Etapas;
