import { DatePicker, Space } from 'antd';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function DateFilter({ start, end }) {
  const location = useLocation();
  const history = useHistory();

  const disabledStartDate = (startValue) => {
    if (!startValue || !end) {
      return false;
    }
    return startValue.valueOf() > end.valueOf();
  };

  const disabledEndDate = (endValue) => {
    if (!endValue || !start) {
      return false;
    }
    return endValue.valueOf() <= start.valueOf();
  };

  const setStart = (date) => {
    const query = qs.parse(location.search);
    if (date) {
      date = date.startOf('day');
      query.start = date.valueOf();
    } else {
      delete query.start;
    }

    const search = qs.stringifyUrl({ url: location.pathname, query });
    history.push(search);
  };

  const setEnd = (date) => {
    const query = qs.parse(location.search);
    if (date) {
      date = date.endOf('day');
      query.end = date.valueOf();
    } else {
      delete query.end;
    }

    const search = qs.stringifyUrl({ url: location.pathname, query });
    history.push(search);
  };

  return (
    <Space>
      <DatePicker
        placeholder="Data inicial"
        disabledDate={disabledStartDate}
        value={start}
        onChange={setStart}
        format="DD-MM-YYYY"
        allowClear
      />
      <DatePicker
        placeholder="Data final"
        disabledDate={disabledEndDate}
        value={end}
        onChange={setEnd}
        format="DD-MM-YYYY"
        allowClear
      />
    </Space>
  );
}
