import qs from 'query-string';
import Constants from '../utils/constants';

export const buscarDadosDashboardService = () => Constants.axiosInstance.get('/conversa/dados').then((resp) => resp.data);

export const buscarDadosGraficoService = (data) => Constants.axiosInstance.get(`/conversa/graficoTotal?dataInicial=${data}`).then((resp) => resp.data);

export const buscarConversasEntreDatasService = (dataInicial, dataFinal) => Constants.axiosInstance.get(`/conversa/porData?dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then((resp) => resp.data);

export const buscarListaDeConversasService = ({ search }) => {
  const params = qs.parse(search);
  return Constants.axiosInstance.get('/conversa', { params }).then((resp) => resp.data);
};

export const exportaConversasService = async ({ search }) => {
  const params = qs.parse(search);
  const resp = await Constants.axiosInstance.get('/conversa/export', { params });
  return resp.data;
};

export const buscarUmaConversaService = (conversaId) => Constants.axiosInstance.get(`/conversa/${conversaId}`).then((resp) => resp.data);

export const marcarConversaComoLidaService = (conversaId) => Constants.axiosInstance.get(`/conversa/${conversaId}/lida`).then((resp) => resp.data);
export const buscarEtapas = () => Constants.axiosInstance.get('/etapas').then((resp) => resp.data);
export const buscarProdutos = () => Constants.axiosInstance.get('/produtos').then((resp) => resp.data);
export const totalPorEtapa = () => Constants.axiosInstance.get('/etapas/totalPorEtapa').then((resp) => resp.data);

export const atualizaEtapaDaConversa = (id, etapaDescription) => Constants.axiosInstance.post('/conversa/atualizaEtapa', null, {
  params: {
    id, etapaDescription,
  },
}).then((resp) => resp.data);

export const baixarArquivo = async (arquivo, folderKey) => {
  const { data: download } = await Constants.axiosInstance.get(`/arquivos/download/${arquivo.objectKey}`, { responseType: 'blob' });
  const url = window.URL.createObjectURL(download);
  const linkInvisivel = document.createElement('a');
  linkInvisivel.href = url;
  linkInvisivel.download = arquivo.caption || folderKey || '';
  document.body.appendChild(linkInvisivel);
  linkInvisivel.click();
};
