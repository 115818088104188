import { InboxOutlined } from '@ant-design/icons';
import {
  Form, Input, Modal, notification, Select, Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { atualizarFolder, criarFolder, folderExistePorChave } from '../../servicos/folder_service';

const IMAGE_MAX_SIZE = 5242880;
const DOCUMENT_MAX_SIZE = 104857600;

const defaultFileRules = [
  {
    required: true,
    message: 'Arquivo para o folder é obrigatório',
  },
];

const imageTypes = ['image/jpeg', 'image/png'];

const imageFileRules = [
  {
    required: true,
    message: 'Arquivo para o folder é obrigatório',
  },
  {
    validator: (_, file) => {
      if (file.size > IMAGE_MAX_SIZE) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message: 'Imagem muito grande. O tamanho máximo é 5MB',
  },
  {
    validator: (_, file) => {
      if (!imageTypes.includes(file[0].type)) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message: 'Imagem deve ser jpg ou png',
  },
];

const documentFileRules = [
  {
    required: true,
    message: 'Arquivo para o folder é obrigatório',
  },
  {
    validator: (_, file) => {
      if (file.size > DOCUMENT_MAX_SIZE) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    message: 'Document muito grande. O tamanho máximo é 100MB',
  },
];

const createKeyRules = [
  {
    required: true,
    message: 'Chave do folder é obrigatória',
  },
  {
    pattern: /^[\w-]+$/g,
    message: 'A chave deve conter somente letras, números, _ ou -',
  },
  {
    validator: (_, value) => folderExistePorChave(value),
    message: 'Já existe um folder com essa chave',
  },
];

const updateKeyRules = [
  {
    required: true,
    message: 'Chave do folder é obrigatória',
  },
  {
    pattern: /^[\w-]+$/g,
    message: 'A chave deve conter somente letras, números, _ ou -',
  },
];

function ModalFolder({
  folder, visible, setVisible, loadTable,
}) {
  const [keyIsDisabled, setKeyIsDisabled] = useState(false);
  const [sendingFolder, setSendingFolder] = useState(false);
  const [fileRules, setFileRules] = useState(defaultFileRules);
  const [accept, setAccept] = useState();
  const [keyRules, setKeyRules] = useState(createKeyRules);

  const types = [
    {
      label: 'Imagem',
      value: 'image',
    },
    {
      label: 'Documento',
      value: 'document',
    },
  ];

  const [form] = Form.useForm();

  function setImageValidations() {
    setAccept(imageTypes.join(', '));
    setFileRules(imageFileRules);
    const fileList = form.getFieldValue('file');
    if (fileList && fileList.length && (!imageTypes.includes(fileList[0].type) || fileList[0].size > IMAGE_MAX_SIZE)) {
      form.getFieldValue('file').status = 'error';
    }
  }

  function setDocumentValidations() {
    setAccept(undefined);
    setFileRules(documentFileRules);
  }

  useEffect(() => {
    if (folder.id) {
      const fields = [
        {
          name: 'id',
          value: folder.id,
        },
        {
          name: 'key',
          value: folder.key,
        },
        {
          name: 'arquivo',
          value: folder.arquivo,
        },
        {
          name: 'type',
          value: folder.type,
        },
        {
          name: 'file',
          value: [{
            name: folder.key,
            status: 'done',
            oldFile: true,
            type: folder.arquivo.mime_type,
          },
          ],
        },
      ];

      if (folder.type === 'image') setImageValidations();
      else setDocumentValidations();

      form.setFields(fields);
      setKeyIsDisabled(true);
      setKeyRules(updateKeyRules);
    }
  }, [folder.id]);

  const reset = () => {
    form.resetFields();
    setKeyIsDisabled(false);
    setVisible(false);
    setAccept(undefined);
    setFileRules(defaultFileRules);
    setKeyRules(createKeyRules);
  };

  const onModalCancel = () => {
    reset();
  };

  const sendFolder = async (folder) => {
    setSendingFolder(true);
    try {
      if (folder.id) {
        await atualizarFolder(folder);
      } else {
        await criarFolder(folder);
      }
      reset();
      loadTable();
    } catch (error) {
      // TODO
      notification.error({ message: 'Erro ao enviar folder!' });
    }
    setSendingFolder(false);
  };

  const changeFileValidations = (type) => {
    if (type === 'image') {
      setImageValidations();
    } else if (type === 'document') {
      setDocumentValidations();
    }
    return type;
  };

  return (
    <Modal
      title="Escolha o documento que deseja enviar"
      visible={visible}
      onOk={form.submit}
      confirmLoading={sendingFolder}
      onCancel={onModalCancel}
      okText="Enviar Folder"
      cancelText="Cancelar"
      width={1000}
    >
      <Form
        form={form}
        size="large"
        onFinish={sendFolder}
        layout="vertical"
      >
        <Form.Item
          name="id"
          hidden
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="arquivo"
          hidden
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="key"
          label="Chave"
          validateTrigger="onBlur"
          validateFirst
          rules={keyRules}

        >
          <Input placeholder="Chave única" disabled={keyIsDisabled} />
        </Form.Item>

        <Form.Item
          name="type"
          label="Tipo"
          rules={[{ required: true, message: 'Tipo do folder é obrigatório' }]}
          getValueFromEvent={changeFileValidations}
        >
          <Select options={types} placeholder="Selecione o tipo da mídia" />
        </Form.Item>
        <Form.Item
          label="Arquivo"
          name="file"
          valuePropName="fileList"
          validateFirst
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            if (e && e.file && e.file.status !== 'removed') { return [e.file]; }
            return [];
          }}
          rules={fileRules}
        >
          <Upload.Dragger
            listType="picture"
            name="file"
            beforeUpload={(file) => {
              if (file.size > DOCUMENT_MAX_SIZE) {
                file.status = 'error';
                notification.error({
                  message: 'Arquivo muito grande!',
                  description: 'O tamanho de máximo de um documento é 100MB',
                });
              }

              return false;
            }}
            accept={accept}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Clique ou arraste um arquivo para a esta área</p>

          </Upload.Dragger>
        </Form.Item>
      </Form>

    </Modal>
  );
}

ModalFolder.propTypes = {
  folder: PropTypes.object,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  loadTable: PropTypes.func,
};

ModalFolder.defaultProps = {
  folder: {},
  visible: false,
  setVisible: () => { },
  loadTable: () => { },
};

export default ModalFolder;
