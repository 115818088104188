import { DownloadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import React, { useState } from 'react';
import { baixarArquivo } from '../../servicos/cockpit_service';

function ConteudoMensagem({ mensagem }) {
  const [baixando, setBaixando] = useState(false);

  function abreNotificaoErro() {
    notification.error({
      message: 'Ocorreu um erro ao fazer download do arquivo',
      placement: 'bottomRight',
    });
  }

  if (mensagem.arquivo) {
    return (
      <Button
        icon={<DownloadOutlined />}
        loading={baixando}
        onClick={async () => {
          setBaixando(true);
          try {
            await baixarArquivo(mensagem.arquivo);
          } catch (e) {
            abreNotificaoErro();
          }
          setBaixando(false);
        }}
      >
        {mensagem.arquivo.caption}
      </Button>
    );
  }
  return mensagem.conteudo;
}

export default ConteudoMensagem;
