import { FrownOutlined } from '@ant-design/icons';
import { Layout } from 'antd';

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { basePath as basename } from '../../utils/config.json';

const { Content } = Layout;

function PaginaNaoEncontrada() {
  return (
    <Layout>
      <Content>
        <div
          style={{
            paddingTop: '5%', fontSize: 40, color: 'gray', width: '50%',
          }}
          className="text-center"
        >
          <div style={{
            border: '0.2px solid gray', borderRadius: '10px', boxShadow: '1px 1px 5px gray', padding: 5,
          }}
          >
            <FrownOutlined />
            <p>Página Não Encontrada 404</p>
            <a style={{ fontSize: 20 }} href={`${basename}/login`}>Voltar</a>
          </div>
        </div>
      </Content>
    </Layout>
  );
}

function mapStateToProps() {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginaNaoEncontrada);
