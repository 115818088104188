import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import { baixarArquivo } from '../../servicos/cockpit_service';

export default function BaixarFolderButton({ folder }) {
  const [baixando, setBaixando] = useState(false);

  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={async () => {
        setBaixando(true);
        try {
          await baixarArquivo(folder.arquivo, folder.key);
        } catch (e) {
          console.error(e);
        }
        setBaixando(false);
      }}
      loading={baixando}
    >
      {folder.key}
    </Button>
  );
}
