import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/api',
});

axiosInstance.interceptors.request.use(
  (config) => {
    let authToken = localStorage.getItem('authToken');
    const configCopy = { ...config };
    if (authToken) {
      authToken = `Bearer ${authToken}`;
      if (configCopy.headers) {
        configCopy.headers.Authorization = authToken;
      } else {
        configCopy.headers = { Authorization: authToken };
      }
    }
    return configCopy;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      localStorage.removeItem('authToken');
    }
    return Promise.reject(error);
  },
);
export default {
  axiosInstance,
};
