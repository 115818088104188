import {
  CheckCircleOutlined, LoadingOutlined,

  MessageOutlined, RobotOutlined,

  SmileOutlined,
  WarningOutlined,
  LikeOutlined,
  DislikeOutlined,
} from '@ant-design/icons';
import {
  Card, Col, Layout, Row,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { buscarDadosDashboard } from '../../actions/cockpit_actions';
import Graficos from './graficos';
import SiderCockpit from './sider-cockpit';
import TotalEtapas from './totalEtapas';

const { Content } = Layout;

class LeadsCockpit extends Component {
  constructor(props) {
    super(props);

    this.state = {

      dashboard_data_is_fetching: true,
    };
  }

  componentDidMount() {
    this.props.buscarDadosDashboard().then(() => {
      this.setState({ dashboard_data_is_fetching: false });
    }).catch(() => {
      this.setState({ dashboard_data_is_fetching: false, erro_encontrado: true });
    });
  }

  verificaExistenciaDadosDashboard(valor) {
    if (this.state.dashboard_data_is_fetching) {
      return <LoadingOutlined />;
    }

    if (this.state.erro_encontrado) {
      return 'Ocorreu um erro ao buscar os Dados, Tente novamente.';
    } switch (valor) {
      case 'total_conversa':
        return this.props.cockpit_data.totalConversas !== null ? this.props.cockpit_data.totalConversas : '-';
      case 'total_mensagens':
        return this.props.cockpit_data.totalMensagens !== null ? this.props.cockpit_data.totalMensagens : '-';
      case 'media_satisfacao':
        return this.props.cockpit_data.mediaFeedback !== null ? this.props.cockpit_data.mediaFeedback.toFixed(2) : '-';
      case 'atend_finalizados':
        return this.props.cockpit_data.totalFinalizada !== null ? this.props.cockpit_data.totalFinalizada : '-';
      case 'atend_nfinalizados':
        return this.props.cockpit_data.totalNaoFinalizada !== null ? this.props.cockpit_data.totalNaoFinalizada : '-';
      case 'cnpj_informados':
        return this.props.cockpit_data.cnpjInformados !== null ? this.props.cockpit_data.cnpjInformados : '-';
      case 'cpf_informados':
        return this.props.cockpit_data.cpfInformados !== null ? this.props.cockpit_data.cpfInformados : '-';
      case 'cnpj_ninformados':
        return this.props.cockpit_data.cnpjNaoInformados !== null ? this.props.cockpit_data.cnpjNaoInformados : '-';
      case 'cnpj_invalidos':
        return this.props.cockpit_data.cnpjInvalidos !== null ? this.props.cockpit_data.cnpjInvalidos : '-';
      default:
        return null;
    }
  }

  render() {
    return (
      <Layout>
        <SiderCockpit />
        <Content style={{ background: '#E5E5E5', minHeight: '100vh' }}>
          <Card bordered title="Dashboard" style={{ margin: 5 }} bodyStyle={{ padding: 10 }}>
            <Row gutter={10} style={{ paddingLeft: 25 }}>
              <Col span={6} offset={3}>
                <Card className="card-conversas" size="small" title={<RobotOutlined className="card-span-total-conversas" />}>
                  <p className="resultado-header">Total de Conversas</p>
                  <p className={this.state.erro_encontrado ? 'resultado-texto-erro' : 'resultado-texto'}>{this.verificaExistenciaDadosDashboard('total_conversa')}</p>
                </Card>
              </Col>
              <Col span={6} offset={0}>
                <Card className="card-atend-finalizado" size="small" title={<LikeOutlined className="card-span-total-mensagens" />}>
                  <p className="resultado-header">CNPJ informados</p>
                  <p className={this.state.erro_encontrado ? 'resultado-texto-erro' : 'resultado-texto'}>{this.verificaExistenciaDadosDashboard('cnpj_informados')}</p>
                </Card>
              </Col>
              <Col span={6} offset={0}>
                <Card className="card-atend-nfinalizado" size="small" title={<DislikeOutlined className="card-span-media-satisfacao" />}>
                  <p className="resultado-header">{`CNPJ não informados: ${this.verificaExistenciaDadosDashboard('cnpj_ninformados')}`}</p>
                  <p className="resultado-header">{`CPF informados: ${this.verificaExistenciaDadosDashboard('cpf_informados')}`}</p>
                  <p className="resultado-header">{`CNPJ ou CPF inválidos: ${this.verificaExistenciaDadosDashboard('cnpj_invalidos')}`}</p>
                </Card>
              </Col>
            </Row>
            <Row gutter={10} style={{ paddingLeft: 25 }}>
              <Col span={6}>
                <Card className="card-mensagens" size="small" title={<MessageOutlined className="card-span-total-mensagens" />}>
                  <p className="resultado-header">Total de Mensagens</p>
                  <p className={this.state.erro_encontrado ? 'resultado-texto-erro' : 'resultado-texto'}>{this.verificaExistenciaDadosDashboard('total_mensagens')}</p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="card-media-satisfacao" size="small" title={<SmileOutlined className="card-span-media-satisfacao" />}>
                  <p className="resultado-header">Média de Satisfação</p>
                  <p className={this.state.erro_encontrado ? 'resultado-texto-erro' : 'resultado-texto'}>{this.verificaExistenciaDadosDashboard('media_satisfacao')}</p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="card-atend-finalizado" size="small" title={<CheckCircleOutlined className="card-span-total-atend-finalizado" />}>
                  <p className="resultado-header">Atend. Finalizados Cloe</p>
                  <p className={this.state.erro_encontrado ? 'resultado-texto-erro' : 'resultado-texto'}>{this.verificaExistenciaDadosDashboard('atend_finalizados')}</p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="card-atend-nfinalizado" size="small" title={<WarningOutlined className="card-span-total-atend-nfinalizado" />}>
                  <p className="resultado-header">Atendimentos Inside Sales</p>
                  <p className={this.state.erro_encontrado ? 'resultado-texto-erro' : 'resultado-texto'}>{this.verificaExistenciaDadosDashboard('atend_nfinalizados')}</p>
                </Card>
              </Col>
            </Row>
          </Card>
          <TotalEtapas />
          <Card bordered title="Gráficos de Medição" style={{ margin: 5 }} bodyStyle={{ padding: 10 }}>
            <Graficos />
          </Card>
        </Content>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {

    cockpit_data: state.cockpit.dashboard_data,

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ buscarDadosDashboard }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadsCockpit));
