import { ACAO_ALERTA_SELECIONADA, RESETA_ACAO } from '../actions/alerta_actions';

export const INITIAL_STATE = {

  acaoSelecionada: '',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACAO_ALERTA_SELECIONADA:
      return { ...state, acaoSelecionada: action.acao };
    case RESETA_ACAO:
      return { ...state, acaoSelecionada: '' };
    default:
      return state;
  }
}
