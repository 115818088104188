import { combineReducers } from 'redux';
import Login from './login';
import SiderMenu from './sider_menu';
import Cockpit from './cockpit';
import GerenciadorAlertas from './alerta';

const rootReducer = combineReducers({

  login: Login,
  sider_menu: SiderMenu,
  cockpit: Cockpit,
  alertas: GerenciadorAlertas,

});

export default rootReducer;
