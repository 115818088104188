import LoginService from '../servicos/login_service';

export const EFETUA_LOGOUT = 'EFETUA_LOGOUT';
export const FALHA_LOGIN = 'FALHA_LOGIN';
export const LIMPAR_ERROS_LOGIN = 'LIMPAR_ERROS_LOGIN';

export const efetuaLogin = (usuario, senha) => () => LoginService.login(usuario, senha);

export const efetuaLogOut = () => () => localStorage.removeItem('authToken');

export const falhaAoLogar = (msg) => ({

  type: FALHA_LOGIN,
  mensagem: msg,

});

export const limparErrosLogin = () => ({

  type: LIMPAR_ERROS_LOGIN,
});
