import {
  CalendarOutlined,
  FunnelPlotOutlined,
  HomeOutlined, IdcardOutlined, MailOutlined, MessageOutlined, PhoneOutlined, SearchOutlined, ShoppingCartOutlined, UserOutlined, WarningFilled,
} from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import { maskJs } from 'mask-js';
import moment from 'moment';
import React from 'react';

function getColumns(exibePainelPorNumeroSelecionado) {
  return [
    {
      title: (
        <div style={{ color: '#909ba7' }}>
          <PhoneOutlined />
          {' '}
          Número
        </div>
      ),
      dataIndex: 'telefoneCliente',
      align: 'center',
      render: (numeroContato, conversa) => (
        <div
          key="numeroContato"
          style={{
            color: 'blue', textDecoration: 'underline', cursor: 'pointer', display: 'inline',
          }}
          onClick={() => exibePainelPorNumeroSelecionado(conversa.id, numeroContato, conversa.key)}
        >
          {maskJs('+99 (99) 9999?9-9999', numeroContato)}
          {conversa.demonstrouInteresse ? (
            <Tooltip key="tooltip_alerta_telefone" placement="rightTop" title="Esta conversa pode precisar de uma intervenção humana.">
              <WarningFilled
                key="alerta_contato"
                style={{ fontSize: 20, float: 'right', color: '#f79d1e' }}
              />
            </Tooltip>
          ) : ''}
          {conversa.novaMensagemRecebida ? (
            <Tooltip key="tooltip_alerta_nova_mensagem" placement="rightTop" title="Nova mensagem para esta conversa.">
              <MessageOutlined
                className="novaMensagemRecebida"
                style={{ fontSize: 20, float: 'right', color: 'darkgreen' }}
              />
            </Tooltip>
          ) : ''}

        </div>
      ),
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar por número"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys(['$']);
              confirm();
              setSelectedKeys([]);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Resetar
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#838587' : undefined }} />
      ),
    },
    {
      title: (
        <div style={{ color: '#838587' }}>
          <IdcardOutlined />
          {' '}
          CNPJ
        </div>
      ),
      dataIndex: 'cnpj',
      align: 'center',
      render: (cnpj) => (cnpj ? maskJs('99.999.999/9999-99', cnpj) : '-'),
    },
    {
      title: (
        <div style={{ color: '#838587' }}>
          <UserOutlined />
          {' '}
          Nome do Contato
        </div>
      ),
      dataIndex: 'nomeContato',
      align: 'center',
      render: (nomeContato) => (nomeContato || '-'),
    },
    {
      title: (
        <div style={{ color: '#838587' }}>
          <HomeOutlined />
          {' '}
          Razão Social
        </div>
      ),
      dataIndex: 'razaoSocial',
      align: 'center',
      render: (razaoSocial) => (razaoSocial || '-'),
    },
    {
      title: (
        <div style={{ color: '#838587' }}>
          <MailOutlined />
          {' '}
          E-mail
        </div>
      ),
      dataIndex: 'emailCliente',
      align: 'center',
      render: (emailContato) => (emailContato || '-'),
    },
    {
      title: (
        <div style={{ color: '#838587' }}>
          <FunnelPlotOutlined />
          {' '}
          Etapa do funil
        </div>
      ),
      align: 'center',
      dataIndex: 'etapaAtual',
      sorter: true,
      filterMultiple: false,
      render: (etapaAtual) => {
        if (etapaAtual) {
          if (etapaAtual.parentDescription) {
            return (
              <>
                {etapaAtual.parentFrontendDescription}
                <br />
                <em>
                  {etapaAtual.frontendDescription}
                </em>
              </>
            );
          }
          return (
            <>
              {etapaAtual.frontendDescription}
            </>
          );
        }
        return '-';
      },
    },
    /* Coluna Produto */
    {
      title: (
        <div style={{ color: '#838587' }}>
          <ShoppingCartOutlined />
          {' '}
          Produto
        </div>
      ),
      dataIndex: 'produto',
      align: 'center',
      filterMultiple: false,
      sorter: true,
      render: (produto) => (produto ? produto.value : '-'),
    },
    {
      title: (
        <div style={{ color: '#838587' }}>
          <CalendarOutlined />
          {' '}
          Data da Conversa
        </div>
      ),
      dataIndex: 'dataConversa',
      sorter: true,
      align: 'center',
      render: (dataConversa) => moment(dataConversa).locale('pt-br').format('L HH:mm'),
    },

  ];
}

export default getColumns;
