import { SELECIONA_OPCAO_MENU } from '../actions/sider_menu_actions';

function selecionaOpcaoMenuPadrao() {
  switch (window.location.pathname) {
    case '/cockpit':
      return ['1'];
    case '/conversas':
      return ['2'];
    default:
      return ['1'];
  }
}
export const INITIAL_STATE = {

  opcaoSelecionada: selecionaOpcaoMenuPadrao(),
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SELECIONA_OPCAO_MENU:
      return { ...state, opcaoSelecionada: action.opcao };
    default:
      return state;
  }
}
