import Constants from '../utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const buscarFolders = async ({ current, pageSize }) => {
  const params = {
    page: current - 1,
    size: pageSize,
    sort: 'key,ASC',
  };
  const { data } = await Constants.axiosInstance.get('/folders', { params });
  return data;
};
export const folderExistePorChave = async (key) => {
  const params = { key };
  const { data } = await Constants.axiosInstance.get('/folders/existsByKey', { params });
  if (data.exists) throw new Error('Folder já existe com essa chave');
  return data.exists;
};
export const removerFolder = (id) => Constants.axiosInstance.delete(`/folders/${id}`);

export const criarFolder = async (formFolder) => {
  const folder = {
    key: formFolder.key,
    type: formFolder.type,
  };

  const formData = new FormData();
  formData.append('folder', new Blob([JSON.stringify(folder)], { type: 'application/json' }));
  formData.append('file', formFolder.file[0]);

  const resp = await Constants.axiosInstance.post('/folders', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return resp.data;
};
export const atualizarFolder = async (formFolder) => {
  const folder = {
    id: formFolder.id,
    key: formFolder.key,
    type: formFolder.type,
    arquivo: formFolder.arquivo,
  };
  console.log(folder);

  const formData = new FormData();
  formData.append('folder', new Blob([JSON.stringify(folder)], { type: 'application/json' }));
  if (!formFolder.file.oldFile) { formData.append('file', formFolder.file[0]); }

  const resp = await Constants.axiosInstance.put('/folders', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return resp.data;
};
