import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import GerenciadorAlertas from '../componentes/alertas/gerenciador_de_alertas';
import { basePath as basename } from '../utils/config.json';
import routesConfig from './routes_config';

function RotasAplicacao({ isAuthorized }) {
  return (
    <Router basename={basename}>
      <div>
        <GerenciadorAlertas />
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (!isAuthorized) { return <routesConfig.login.component />; }
              return <Redirect to={routesConfig.cockpit.path} />;
            }}
          />
          <Route
            exact
            path={routesConfig.login.path}
            render={() => {
              if (!isAuthorized) { return <routesConfig.login.component />; }
              return <Redirect to={routesConfig.cockpit.path} />;
            }}
          />
          <Route
            exact
            path={routesConfig.cockpit.path}
            render={() => {
              if (isAuthorized) {
                return <routesConfig.cockpit.component />;
              }
              return <Redirect to={routesConfig.login.path} />;
            }}
          />
          <Route
            exact
            path={routesConfig.folders.path}
            render={() => {
              if (isAuthorized) {
                return <routesConfig.folders.component />;
              }
              return <Redirect to={routesConfig.login.path} />;
            }}
          />
          <Route
            exact
            path={routesConfig.conversas.path}
            render={() => {
              if (isAuthorized) {
                return <routesConfig.conversas.component />;
              }
              return <Redirect to={routesConfig.login.path} />;
            }}
          />
          <Route component={routesConfig.notfoundpage.component} />
        </Switch>
      </div>
    </Router>
  );
}

RotasAplicacao.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

function mapStateToProps() {
  const isAuthorized = !!localStorage.getItem('authToken');

  return {
    isAuthorized,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RotasAplicacao);
