export const ACAO_ALERTA_SELECIONADA = 'ACAO_ALERTA_SELECIONADA';
export const RESETA_ACAO = 'RESETA_ACAO';

export const acaoAlertaSelecionada = (acao) => ({

  type: ACAO_ALERTA_SELECIONADA,
  acao,
});

export const resetaAcao = () => ({

  type: RESETA_ACAO,
});
