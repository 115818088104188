import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { resetaAcao } from '../../actions/alerta_actions';
import { efetuaLogOut } from '../../actions/login_actions';

import { basePath as basename } from '../../utils/config.json';

const alerta = withReactContent(Swal);

class GerenciadorAlertas extends Component {
  alertaDeLogOut() {
    alerta.fire({
      type: 'warning',
      title: <div className="lblTituloAlerta">Deseja sair da Aplicação?</div>,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      confirmButtonClass: 'btnConfirmarAlerta',
      cancelButtonClass: 'btnCancelarAlerta',
      buttonsStyling: true,
    }).then((resultado) => {
      if (resultado.value) {
        alerta.fire({
          type: 'success',
          title: <div className="lblTituloAlerta">Deslogado com Sucesso!</div>,
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonClass: 'btnConfirmarAlerta',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          buttonsStyling: true,
        }).then(() => {
          this.props.efetuaLogOut();
          this.props.resetaAcao();
          window.location.pathname = `${basename}/login`;
        });
      } else {
        this.props.resetaAcao();
      }
    });
  }

  renderizaAlerta() {
    switch (this.props.acao) {
      case 'logout':
        return this.alertaDeLogOut();
      default:
    }
    return null;
  }

  render() {
    return (
      <div>{this.renderizaAlerta()}</div>
    );
  }
}

function mapStateToProps(state) {
  return {

    acao: state.alertas.acaoSelecionada,

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetaAcao, efetuaLogOut }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GerenciadorAlertas);
