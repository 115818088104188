import moment from 'moment';
import { maskJs } from 'mask-js';
import { parse } from 'json2csv';

const verificaSeObjetoEhVazio = (obj) => (Object.entries(obj).length === 0 && obj.constructor === Object);

const naoNulo = (variavel) => variavel !== undefined && variavel !== null;

const transformaDados = (conversa) => {
  const numero = maskJs('+99 (99) 9999?9-9999', conversa.telefoneCliente) || '';
  const cnpj = maskJs('99.999.999/9999-99', conversa.cnpj) || '';
  const nomeContato = conversa.nomeContato || '';
  const razaoSocial = conversa.razaoSocial || '';
  const email = conversa.emailCliente || '';
  let conversaFinalizada = '';
  if (naoNulo(conversa.finalizada)) {
    conversaFinalizada = conversa.finalizada ? 'Sim' : 'Não';
  }
  let etapaFunil = '';
  if (naoNulo(conversa.etapaAtual)) {
    etapaFunil = conversa.etapaAtual.parentDescription ? `${conversa.etapaAtual.parentFrontendDescription} (${conversa.etapaAtual.frontendDescription})` : conversa.etapaAtual.frontendDescription;
  }
  const produto = conversa.produto ? conversa.produto.value : '';
  const dataConversa = conversa.dataConversa ? moment(conversa.dataConversa).format('DD/MM/YYYY HH:mm:ss') : '';
  const feedback = conversa.feedback || '';
  const sugestao = conversa.sugestao || '';

  return {
    numero,
    cnpj,
    nomeContato,
    razaoSocial,
    email,
    conversaFinalizada,
    dataConversa,
    feedback,
    sugestao,
    etapaFunil,
    produto,
  };
};

const exportaConversas = (conversas) => {
  const csvContent = 'data:text/csv;charset=utf-8,%EF%BB%BF';
  const fields = [
    { label: 'Data da Conversa', value: 'dataConversa' },
    { label: 'Número', value: 'numero' },
    { label: 'CNPJ', value: 'cnpj' },
    { label: 'Nome do Contato', value: 'nomeContato' },
    { label: 'Razão Social', value: 'razaoSocial' },
    { label: 'E-mail', value: 'email' },
    { label: 'Etapa do funil', value: 'etapaFunil' },
    { label: 'Produto', value: 'produto' },
    { label: 'Nota de Feedback', value: 'feedback' },
    { label: 'Sugestão do Cliente', value: 'sugestao' },
  ];
  const transforms = [transformaDados];
  const universalBOM = '\uFEFF';
  const csvFile = universalBOM + parse(conversas, {
    fields, transforms, delimiter: ';',
  });
  const toExport = new Blob([csvFile], { type: csvContent });
  const oldData = document.getElementById('conversasExportData');
  if (oldData) document.body.removeChild(oldData);
  const a = document.createElement('a');
  a.id = 'conversasExportData';
  a.href = URL.createObjectURL(toExport);
  a.download = `Conversas_${moment().format('DD-MM-YYYY_HH-mm')}.csv`;
  document.body.appendChild(a);
  a.click();
};

export {

  verificaSeObjetoEhVazio,
  exportaConversas,
  naoNulo,
};
