import {
  CustomerServiceOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MessageOutlined,
  QuestionCircleFilled,
  RobotOutlined,
  SettingFilled,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Button,
  Card,
  Col,
  Input,
  Layout,
  List,
  notification,
  Popconfirm,
  Popover,
  Row,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import uuid from 'uuid/v1';
import {
  adicionaMensagem, atualizarStatusMensagemLida, buscarListaDeConversas, buscarUmaConversa, comecaLiveChat, encerrarLiveChat, enviarMensagemLiveChat, exportaConversasAction, limparListaConversas, limparLiveChat, livechatIniciado, marcarConversaComoLida, salvaIntervalId,
} from '../../actions/cockpit_actions';
import { atualizaEtapaDaConversa, buscarEtapas, buscarProdutos } from '../../servicos/cockpit_service';
import { verificaSeObjetoEhVazio } from '../../utils/utils';
import SiderCockpit from '../cockpit/sider-cockpit';
import getColumns from './columns';
import DateFilter from './dateFilter';
import detalheConversa from './detalheConversa';
import Etapas from './etapas';
import Mensagem from './mensagem';
import ModalMedia from './modalMedia';

const { Content } = Layout;

const abrirNotificacaoErroMensagem = () => {
  notification.error({
    message: 'Erro ao enviar Mensagem',
    description:
      'Sua mensagem não foi enviada, tente novamente.',
  });
};

const abrirNotificacaoErroExportacao = () => {
  notification.error({
    message: 'Erro ao tentar exportar dados',
    description: 'Não foi possível obter os dados das conversas solicitadas. Por favor, tente novamente.',
  });
};

class Conversas extends Component {
  constructor() {
    super();
    this.state = {
      detalhesConversa: '',
      conversasAreFetching: true,
      checking_livechat_channel: false,
      enviandoMensagem: false,
      mensagem: '',
      obj_mensagem: {},
      carregando_exportacao: false,
      modalDocumentoVisivel: false,
      etapas: [],
      downloadConfirmVisible: false,
      atualizandoEtapa: false,
      start: undefined,
      end: undefined,
      lastClientMessageMoment: undefined,
    };

    this.exibePainelPorNumeroSelecionado = this.exibePainelPorNumeroSelecionado.bind(this);
    this.handlePaginacaoTabela = this.handlePaginacaoTabela.bind(this);
    this.exportarDadosDeConversa = this.exportarDadosDeConversa.bind(this);
    this.changeEtapaDaConversa = this.changeEtapaDaConversa.bind(this);
    this.buscaConversas = this.buscaConversas.bind(this);
    this.enviarMediaAoCliente = this.enviarMediaAoCliente.bind(this);
    this.setVisible = this.setVisible.bind(this);

    this.contentLegenda = (
      <div>
        <Badge color="#008cad" text="Cloe" />
        <br />
        <Badge color="#e0fff7" text="Cliente" />
        <br />
        <Badge color="#fff7fd" text="Atendente" />
      </div>
    );
  }

  componentDidMount() {
    this.inicializaColunas();

    this.buscaConversas();
    buscarProdutos().then((produtos) => {
      this.updateProdutosFilter(produtos);
    });
    buscarEtapas().then((etapas) => {
      this.updateEtapaFilter(etapas);
      this.setState({ etapas });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.buscaConversas();
    }
  }

  componentWillUnmount() {
    this.props.limparListaConversas();
    this.props.limparLiveChat();
    this.limpaIntervalo();
  }

  handleEnvioDeMensagemPorTecla(e) {
    if (e.key === 'Enter') {
      this.enviarMensagemAoCliente({
        numero: this.props.conversaSelecionada.telefoneCliente,
        text: this.state.mensagem,
        conversaId: this.props.conversaSelecionada.id,
      });
    }
  }

  handlePaginacaoTabela(pagination, filters, sorter) {
    const query = {
      page: pagination.current - 1,
      size: pagination.pageSize,
    };

    if (sorter && sorter.order) {
      const { field, order } = sorter;
      const backendOrder = order === 'ascend' ? 'ASC' : 'DESC';
      if (field === 'etapaAtual') {
        query.sort = `etapaAtual.frontendDescription,${backendOrder}`;
      } else if (field === 'produto') {
        query.sort = `produto.value,${backendOrder}`;
      } else {
        query.sort = `${field},${backendOrder}`;
      }
    }

    if (filters && filters.telefoneCliente) {
      if (filters.telefoneCliente[0] !== '$') {
        [query.telefoneCliente] = filters.telefoneCliente;
      }
    }

    if (filters && filters.etapaAtual) {
      [query.etapa] = filters.etapaAtual;
    }

    if (filters && filters.produto) {
      [query.produto] = filters.produto;
    }

    if (this.state.start) {
      query.start = this.state.start.valueOf();
    }

    if (this.state.end) {
      query.end = this.state.end.valueOf();
    }

    const { location, history } = this.props;
    const search = qs.stringifyUrl({ url: location.pathname, query });
    history.push(search);
  }

  handleLiveChatChannel() {
    this.setState({ checking_livechat_channel: true });

    const numero = this.props.conversaSelecionada.telefoneCliente;
    const conversaId = this.props.conversaSelecionada.id;

    if (this.props.livechat.iniciou) {
      this.props.encerrarLiveChat(numero).then(() => {
        this.limpaIntervalo();
      }).catch(() => {
      }).then(() => {
        this.setState({ checking_livechat_channel: false });
      });
    } else {
      this.props.comecaLiveChat({ numero, conversaId }).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
        this.criaIntervaloParaConversa();
      }).catch(() => { }).then(() => { this.setState({ checking_livechat_channel: false }); });
    }
  }

  handleNotaFeedback() {
    if (this.props.conversaSelecionada.feedback !== null && this.props.conversaSelecionada.feedback !== undefined) {
      return parseFloat((this.props.conversaSelecionada.feedback / 2).toFixed(2));
    }
    return null;
  }

  handleMensagem(msg) {
    this.setState({ mensagem: msg });
  }

  setVisible(visible) {
    this.setState({ modalDocumentoVisivel: visible });
  }

  canSendMessage() {
    if (this.state.lastClientMessageMoment) {
      return moment().subtract(1, 'day').isBefore(this.state.lastClientMessageMoment);
    }
    return false;
  }

  inicializaColunas() {
    this.setState({ columns: getColumns(this.exibePainelPorNumeroSelecionado) });
  }

  updateEtapaFilter(etapas = []) {
    const etapaFilters = etapas.map((etapa) => ({
      value: etapa.description,
      text: etapa.frontendDescription,
    }));
    const { columns } = this.state;
    columns[5].filters = etapaFilters;
    this.setState({ columns: [...columns] });
  }

  updateProdutosFilter(produtos = []) {
    const produtoFilters = produtos.map((produto) => ({
      value: produto.key,
      text: produto.value,
    }));
    const { columns } = this.state;
    columns[6].filters = produtoFilters;
    this.setState({ columns: [...columns] });
  }

  highlightTableFiltersAndSorters() {
    const { location } = this.props;
    const queryParams = qs.parse(location.search);
    let sortOrder = false;
    let sortField = '';
    let filteredEtapaValue = [];
    let filteredProdutoValue = [];
    let filteredTelefoneClienteValue = [];
    if (queryParams.sort) {
      const sort = queryParams.sort.split(',');
      sortOrder = sort[1] === 'ASC' ? 'ascend' : 'descend';
      [sortField] = sort[0].split('.');
    }

    if (queryParams.etapa) {
      if (queryParams.etapa === 'NEGOCIACAO') {
        const { columns } = this.state;
        if (columns[5].filters[1].value !== 'NEGOCIACAO') {
          columns[5].filters.splice(1, 0, {
            value: 'NEGOCIACAO',
            text: 'Negociação',
          });
          this.setState({ columns: [...columns] });
        }
      }
      filteredEtapaValue = [queryParams.etapa];
    }

    if (queryParams.produto) {
      filteredProdutoValue = [queryParams.produto];
    }

    if (queryParams.start) {
      this.setState({ start: moment(parseInt(queryParams.start, 10)) });
    } else {
      this.setState({ start: undefined });
    }

    if (queryParams.end) {
      this.setState({ end: moment(parseInt(queryParams.end, 10)) });
    } else {
      this.setState({ end: undefined });
    }

    if (queryParams.telefoneCliente) {
      filteredTelefoneClienteValue = [queryParams.telefoneCliente];
    }

    const { columns } = this.state;
    columns[0].filteredValue = filteredTelefoneClienteValue;
    columns[5].filteredValue = filteredEtapaValue;
    columns[6].filteredValue = filteredProdutoValue;
    columns[5].sortOrder = sortField === 'etapaAtual' ? sortOrder : false;
    columns[6].sortOrder = sortField === 'produto' ? sortOrder : false;
    columns[7].sortOrder = sortField === 'dataConversa' ? sortOrder : false;
    this.setState({ columns: [...columns] });
  }

  async buscaConversas() {
    this.setState({ conversasAreFetching: true });
    const { location } = this.props;
    try {
      await this.props.buscarListaDeConversas({ search: location.search });
    } catch (e) {
      console.error(e);
    }
    this.highlightTableFiltersAndSorters();
    this.setState({ conversasAreFetching: false });
  }

  retornaListaDeMensagensTrocadas() {
    if (!verificaSeObjetoEhVazio(this.props.conversaSelecionada)) {
      let lastClientMessageDate;
      const messages = this.props.conversaSelecionada.mensagens.map((mensagem) => {
        if (mensagem.conteudo === 'w4k3up_m3554ge') { return []; }
        if (mensagem.remetente !== 'BANDA KA' && mensagem.remetente !== 'ATENDENTE') {
          lastClientMessageDate = mensagem.dataMsg;
        }

        return <Mensagem key={mensagem.id} mensagem={mensagem} />;
      });

      if (lastClientMessageDate) {
        if (!this.state.lastClientMessageMoment || this.state.lastClientMessageMoment.valueOf() !== lastClientMessageDate) {
          this.setState({ lastClientMessageMoment: moment(lastClientMessageDate) });
        }
      }

      return messages;
    }

    return (
      <p className="text-center" style={{ fontSize: 30, paddingTop: '5%' }}>
        <MessageOutlined />
        <br />
        Selecione um número para visualizar a conversa.
      </p>
    );
  }

  retornaDetalhesDaConversa() {
    if (this.props.conversaSelecionada) {
      return this.setState({
        detalhesConversa: detalheConversa(this.props.conversaSelecionada),
      });
    }
    return <></>;
  }

  criaIntervaloParaConversa() {
    const intervalId = setInterval(() => {
      const qtdeMsg = ((this.props.conversaSelecionada || {}).mensagens || []).length;
      this.props.buscarUmaConversa(this.props.conversaSelecionada.id).then(() => {
        if (qtdeMsg !== this.props.conversaSelecionada.mensagens.length) {
          const elemento = document.getElementById('container-mensagens');
          elemento.scrollTop = elemento.scrollHeight;
        }
      });
    }, 3000);
    this.props.salvaIntervalId(intervalId);
  }

  limpaIntervalo() {
    if (this.props.interval_id) {
      clearInterval(this.props.interval_id);
      this.props.salvaIntervalId(0);
    }
  }

  exportarDadosDeConversa() {
    this.setState({ carregando_exportacao: true });

    this.props.exportaConversasAction(this.props.location)
      .catch(() => {
        abrirNotificacaoErroExportacao();
      }).then(() => {
        this.setState({ carregando_exportacao: false });
      });
  }

  contentLiveChat() {
    return (
      <div>
        <p>
          Canal Selecionado:
          {this.props.livechat.iniciou ? [<CustomerServiceOutlined key="icone_usuario" style={{ fontSize: 20 }} />, ' Atendente'] : [<RobotOutlined key="icone_robot" style={{ fontSize: 20 }} />, ' Cloe']}
        </p>
        <div style={{ display: 'inline' }}>
          Trocar Canal para Usuário&nbsp;
          <Switch loading={this.state.checking_livechat_channel} checked={this.props.livechat.iniciou} onChange={() => this.handleLiveChatChannel()} unCheckedChildren="Desligado" checkedChildren="Ligado" />
        </div>
      </div>
    );
  }

  exibePainelPorNumeroSelecionado(conversaId, numeroContato, key) {
    const containerDiv = document.getElementById('container-mensagens');
    containerDiv.scrollTop = 0;

    this.limpaIntervalo();
    this.props.buscarUmaConversa(conversaId).then(() => {
      this.props.marcarConversaComoLida(conversaId);
      if (key) { this.props.atualizarStatusMensagemLida(key); }
      this.verificaCanalAtual(numeroContato);

      return this.retornaDetalhesDaConversa();
    }).then(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  }

  retornaExportacaoConversas() {
    return (
      <Card style={{ padding: 10 }} title="Exportação ddas conversas">
        <p>Os dados sobre as conversas serão exportados de acordo com o filtro e ordenação da tabela</p>

        <Button className="btn-export" onClick={this.exportarDadosDeConversa} loading={this.state.carregando_exportacao}>Exportar</Button>
      </Card>
    );
  }

  enviarMensagemAoCliente(obj) {
    if (obj.text) {
      this.limpaIntervalo();

      this.setState({ enviandoMensagem: true, mensagem: '', obj_mensagem: obj }, () => {
        const elemento = document.getElementById('container-mensagens');
        elemento.scrollTop = elemento.scrollHeight;
      });

      this.props.enviarMensagemLiveChat(obj).then(() => {
        const mensagemEnviada = {
          id: uuid(),
          remetente: 'ATENDENTE',
          dataMsg: new Date().getTime(),
          type: 'text',
          conteudo: obj.text,
        };

        this.props.adicionaMensagem(mensagemEnviada);
      }).catch(() => {
        this.setState({ mensagem: obj.text });
        abrirNotificacaoErroMensagem();
      }).then(() => {
        this.setState({ enviandoMensagem: false });

        this.criaIntervaloParaConversa();
      });
    }
  }

  async enviarMediaAoCliente(obj, arquivo) {
    this.limpaIntervalo();

    this.setState({ enviandoMensagem: true, mensagem: '', obj_mensagem: { ...obj, text: arquivo.name } }, () => {
      const elemento = document.getElementById('container-mensagens');
      elemento.scrollTop = elemento.scrollHeight;
    });

    try {
      await this.props.enviarMensagemLiveChat(obj, arquivo);
      const mensagemEnviada = {
        id: uuid(),
        remetente: 'ATENDENTE',
        dataMsg: new Date().getTime(),
        type: obj.type,
        arquivo: {
          caption: arquivo.name,
          objKey: '',
        },
      };

      this.props.adicionaMensagem(mensagemEnviada);

      notification.success({
        message: 'Arquivo enviado com sucesso',
      });
    } catch (error) {
      this.setState({ mensagem: obj.text });
      abrirNotificacaoErroMensagem();
    }

    this.setState({ enviandoMensagem: false });
    this.props.buscarUmaConversa(this.props.conversaSelecionada.id);
    this.criaIntervaloParaConversa();
  }

  verificaCanalAtual(numeroContato) {
    this.setState({ checking_livechat_channel: true });

    this.props.livechatIniciado(numeroContato).then(() => {
      this.setState({ checking_livechat_channel: false });
      if (this.props.livechat.iniciou) {
        if (this.props.conversaSelecionada.id === this.props.livechat.conversaId) {
          this.criaIntervaloParaConversa();
        }
      }
    }).catch(() => {
      this.props.limparLiveChat();
    }).then(() => {
      this.setState({ checking_livechat_channel: false });
    });
  }

  async changeEtapaDaConversa(description) {
    try {
      this.setState({ atualizandoEtapa: true });
      await atualizaEtapaDaConversa(this.props.conversaSelecionada.id, description);
      await this.props.buscarUmaConversa(this.props.conversaSelecionada.id);
    } catch (e) {
      // error
    }
    this.setState({ atualizandoEtapa: false });
  }

  tituloListaConversa() {
    const iniciaExportacao = async () => {
      try {
        this.setState({ carregando_exportacao: true });
        await this.props.exportaConversasAction(this.props.location);
      } catch (e) {
        // do nothing
      }
      this.setState({ carregando_exportacao: false, downloadConfirmVisible: false });
    };

    return (
      <>
        Lista de Conversas
        {' '}
        <Popconfirm
          title="Exportar dados conversas com filtro da tabela aplicado?"
          visible={this.state.downloadConfirmVisible}
          onCancel={() => this.setState({ downloadConfirmVisible: false })}
          onConfirm={iniciaExportacao}
          cancelText="Cancelar"
          okButtonProps={{ loading: this.state.carregando_exportacao }}
        >
          <Button onClick={() => { this.setState({ downloadConfirmVisible: true }); }}>
            <DownloadOutlined />
          </Button>
        </Popconfirm>
      </>
    );
  }

  // updateTimeToAnswer() {
  //   //todo
  // }

  render() {
    const {
      columns, conversasAreFetching, detalhesConversa, atualizandoEtapa, modalDocumentoVisivel, etapas, enviandoMensagem, start, end,
    } = this.state;
    return (
      <Layout>
        <SiderCockpit />
        <Content style={{ background: '#E5E5E5', minHeight: '100vh' }}>
          <Card bordered title={this.tituloListaConversa()} style={{ margin: 5 }} bodyStyle={{ padding: 10 }} extra={<DateFilter start={start} end={end} />}>
            <Table
              onChange={this.handlePaginacaoTabela}
              loading={conversasAreFetching}
              pagination={{ ...this.props.pagination, showTotal: (total) => `Total ${total} conversas` }}
              bordered
              columns={columns}
              dataSource={this.props.conversaData}
              size="small"
              locale={{
                filterReset: 'Resetar',
                triggerDesc: 'Clique para ordenar de forma decrescente',
                triggerAsc: 'Clique para ordenar de forma crescente',
                cancelSort: 'Clique para cancelar ordenação',
                emptyText: (
                  <div>

                    <InfoCircleOutlined style={{ fontSize: 30, padding: 10 }} />
                    <p style={{ fontSize: 20 }}>Sem registros</p>
                  </div>
                ),
              }}
            />
          </Card>
          <Card bordered title="Painel de Visualização" style={{ margin: 5, marginBottom: '2em' }} bodyStyle={{ padding: 10 }}>

            <Row style={{ display: 'flex' }}>
              <Col span={6}>
                <div style={{
                  border: '1px solid lightgray', borderRadius: '5px', margin: 1,
                }}
                >
                  <List
                    locale={{
                      emptyText: (
                        <div>
                          <InfoCircleOutlined style={{ fontSize: 30, padding: 10 }} />
                          <p style={{ fontSize: 20 }}>Selecione um número para visualizar os detalhes.</p>
                        </div>
                      ),
                    }}
                    size="small"
                    bordered
                    dataSource={detalhesConversa}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{
                  maxHeight: '708px', border: '1px solid lightgray', borderRadius: '5px', margin: '0 4px', display: 'flex', flexDirection: 'column',
                }}
                >

                  <div
                    style={{
                      fontSize: 25, borderBottom: '1px solid lightgray', width: '100%',
                    }}
                    className="text-center"
                  >
                    Conversa
                    {' '}
                    {this.state.lastClientMessageMoment
                      && (
                        <Tooltip placement="bottom" title={`Possível responder até: ${this.state.lastClientMessageMoment.clone().add(1, 'day').format('DD/MM/YY HH:mm')}`}>
                          <ExclamationCircleOutlined />
                        </Tooltip>
                      )}

                    <Popover placement="bottomLeft" content={this.contentLegenda} title="Legenda">
                      <QuestionCircleFilled
                        style={{
                          float: 'right', paddingTop: 5, paddingRight: 5, color: 'darkred',
                        }}
                      />
                    </Popover>
                    {!verificaSeObjetoEhVazio(this.props.conversaSelecionada) ? (
                      <Popover placement="bottomLeft" content={this.contentLiveChat()} title="Configurações Live Chat">
                        <SettingFilled
                          style={{
                            float: 'right', paddingTop: 5, paddingRight: 5, color: '#54534e',
                          }}
                        />
                      </Popover>
                    ) : ''}

                  </div>
                  {!verificaSeObjetoEhVazio(this.props.livechat) && this.props.livechat.iniciou && this.props.livechat.conversaId !== this.props.conversaSelecionada.id

                    ? (
                      <div style={{
                        backgroundColor: '#ddeded', boxShadow: '1px 1px 5px #ddeded', textAlign: 'center', color: 'darkred', fontSize: 16,
                      }}
                      >
                        Parece que já existe uma conversa para este Número, para visualizá-la &nbsp;
                        <div onClick={() => this.exibePainelPorNumeroSelecionado(this.props.livechat.conversaId, this.props.conversaSelecionada.telefoneCliente)} style={{ display: 'inline', color: 'blue', cursor: 'pointer' }}>Clique Aqui</div>
                      </div>
                    ) : []}
                  <div
                    id="container-mensagens"
                    style={{
                      minHeight: 500, height: '100%', background: 'linear-gradient(138deg, rgba(233,237,240,1) 0%, rgba(213,220,227,1) 86%', boxShadow: '1px 1px 5px #dee3e3', overflow: 'auto',
                    }}
                  >

                    {this.retornaListaDeMensagensTrocadas()}

                    {enviandoMensagem
                      ? (
                        <p
                          style={{
                            padding: 10, textAlign: 'left', margin: 25, float: 'right', clear: 'both', whiteSpace: 'pre-wrap',
                          }}
                          className="bolha-conversa-atendente"
                        >
                          {this.state.obj_mensagem.text}

                          <span style={{ float: 'right', paddingTop: 20, fontSize: 13 }}><LoadingOutlined loading="true" /></span>

                        </p>
                      ) : []}
                  </div>

                  {this.props.livechat.iniciou && this.props.livechat.conversaId === this.props.conversaSelecionada.id
                    ? (
                      <Tooltip placement="top" title={`Possível responder até: ${this.state.lastClientMessageMoment.clone().add(1, 'day').format('DD/MM/YY HH:mm')}`}>
                        <div className="input-div">
                          <ModalMedia
                            visivel={modalDocumentoVisivel}
                            setVisivel={this.setVisible}
                            numero={this.props.conversaSelecionada.telefoneCliente}
                            text={this.state.mensagem}
                            conversaId={this.props.conversaSelecionada.id}
                            type="document"
                            etapas={etapas}
                            enviarMediaAoCliente={this.enviarMediaAoCliente}
                          />
                          <Input
                            className="input-mensagem"
                            disabled={enviandoMensagem || !this.canSendMessage()}
                            onChange={(e) => this.handleMensagem(e.target.value)}
                            value={this.state.mensagem}
                            onKeyUp={(e) => this.handleEnvioDeMensagemPorTecla(e)}
                            addonBefore={(
                              <Popover
                                placement="topLeft"
                                title="Selecione o tipo de mídia"
                                content={(
                                  <button
                                    type="button"
                                    className="btn-document"
                                    onClick={() => { this.setState({ modalDocumentoVisivel: true }); }}
                                    disabled={enviandoMensagem || !this.canSendMessage()}
                                  >
                                    <FontAwesomeIcon icon={['fas', 'file']} />
                                    {' '}
                                    Documento
                                  </button>
                                )}
                                trigger="click"
                              >
                                <button
                                  type="button"
                                  className="btn-atach"
                                  disabled={enviandoMensagem || !this.canSendMessage()}
                                >
                                  <FontAwesomeIcon icon={['fas', 'paperclip']} />
                                </button>
                              </Popover>
                            )}
                            addonAfter={(
                              <button
                                type="button"
                                className="btn-send-message"
                                onClick={() => this.enviarMensagemAoCliente({ numero: this.props.conversaSelecionada.telefoneCliente, text: this.state.mensagem, conversaId: this.props.conversaSelecionada.id })}
                                disabled={enviandoMensagem || !this.canSendMessage()}
                              >
                                <FontAwesomeIcon icon={['far', 'paper-plane']} />
                              </button>
                            )}
                            type="text"
                            placeholder="Digite para continuar a sua conversa com o cliente..."
                          />
                        </div>
                      </Tooltip>
                    )
                    : []}
                </div>
              </Col>
              <Col span={6}>
                <div style={{
                  border: '1px solid lightgray', borderRadius: '5px', margin: 1,
                }}
                >
                  <div style={{ fontSize: 25, borderBottom: '1px solid lightgray' }} className="text-center">
                    Funil de Vendas
                  </div>
                  {!verificaSeObjetoEhVazio(this.props.conversaSelecionada) && (
                    <>
                      <Card>
                        <Etapas etapas={this.state.etapas} etapaAtual={this.props.conversaSelecionada.etapaAtual} onChange={this.changeEtapaDaConversa} loading={atualizandoEtapa} />
                      </Card>
                    </>
                  )}
                  {verificaSeObjetoEhVazio(this.props.conversaSelecionada) && (
                    <>
                      <Card>
                        <p className="text-center" style={{ fontSize: 30, paddingTop: '5%' }}>
                          ...
                        </p>
                      </Card>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Content>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  let conversaData = [];

  if (state.cockpit.conversa_data.content) {
    conversaData = state.cockpit.conversa_data.content.map((conversa, id) => (
      {
        key: id,
        ...conversa,
      }
    ));
  }

  return {
    pagination: state.cockpit.pagination_config,
    conversaSelecionada: state.cockpit.conversa_selecionada,
    livechat: state.cockpit.livechat,
    interval_id: state.cockpit.interval_id,
    conversaData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    buscarListaDeConversas,
    buscarUmaConversa,
    limparListaConversas,
    livechatIniciado,
    encerrarLiveChat,
    enviarMensagemLiveChat,
    comecaLiveChat,
    salvaIntervalId,
    adicionaMensagem,
    limparLiveChat,
    marcarConversaComoLida,
    atualizarStatusMensagemLida,
    exportaConversasAction,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Conversas));
