import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import ConteudoMensagem from './conteudoMensagem';

export default function Mensagem({ mensagem }) {
  const atendenteOuUsuarioClass = mensagem.remetente === 'ATENDENTE'
    ? 'bolha-conversa-atendente' : 'bolha-conversa-usuario';

  const className = mensagem.remetente === 'BANDA KA'
    ? 'bolha-conversa-bot' : atendenteOuUsuarioClass;

  const color = mensagem.remetente === 'BANDA KA' ? 'white' : 'black';

  const float = mensagem.remetente === 'BANDA KA' || mensagem.remetente === 'ATENDENTE' ? 'right' : 'left';

  return (
    <>
      <p
        key={mensagem.id}
        style={{
          padding: 10, textAlign: 'left', margin: 25, float, clear: 'both', color, whiteSpace: 'pre-wrap',
        }}
        className={className}
      >
        <ConteudoMensagem mensagem={mensagem} />
        <span style={{ float: 'right', paddingTop: 20, fontSize: 13 }}>
          {mensagem.remetente === 'ATENDENTE' && <CheckOutlined />}
          {' '}
          {moment(mensagem.dataMsg).locale('pt-br').format('DD/MM/YY HH:mm')}
        </span>
      </p>
    </>
  );
}
