import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

export default function Spinner() {
  return (
    <>
      <LoadingOutlined
        style={{
          fontSize: '75px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        spin
      />
      <br />
    </>
  );
}
