import { Layout } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import pack from '../../../package.json';

const { Footer } = Layout;

function LeadsFooter() {
  return (
    <Footer className="leads-footer-propriedades">
      v
      {pack.version}
    </Footer>
  );
}

function mapStateToProps() {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsFooter);
